// extracted by mini-css-extract-plugin
export var article = "info-module--article--R36vU";
export var badge = "info-module--badge--EfBMM";
export var button = "info-module--button--7HYrY";
export var flexIntro = "info-module--flex-intro--aVKiK";
export var h1 = "info-module--h1--xrkhD";
export var h2 = "info-module--h2--+KUtw";
export var h3 = "info-module--h3--PwhDG";
export var hr = "info-module--hr--qwwhX";
export var p = "info-module--p--gufnR";
export var page = "info-module--page--TIyDD";
export var photo = "info-module--photo--KOux6";
export var textCenter = "info-module--text-center--wZr4-";